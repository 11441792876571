<template>
    <base-page>
        
        <el-card style='margin:5px 0px 5px 0px; padding: 50px; text-align: center'>
           <strong>Nu aveti drepturi</strong>
        </el-card>

    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";

    export default {
        name: "no-rights-page",
        extends: BasePage,
        components: {
            'base-page': BasePage,
        },
        data () {
            return {
              
            }
        },
        methods: {

        },
        mounted(){
            this.base_url = settings.BASE_URL;
        }
    };
</script>

<style lang="less" scoped>

  

</style>
